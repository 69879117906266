/* bootstrap variables custom here */


/** memo : breakpoints 
None	<576px
sm	≥576px
md	≥768px
lg	≥992px
xl	≥1200px
xxl	≥1400px
**/

//$primary : #E71F73;
$primary : #de0d6d; // better for contrast
$secondary : #1D71B8;
$success : #3AAA35;
$font-family-base: 'Lato', sans-serif;
$nav-link-padding-y: 0.25rem;
//$nav-link-hover-color: darken($primary, 10%);
$nav-link-hover-color: rgba($primary, 0.1);
$dropdown-border-color: $primary;
$dropdown-color:$primary;
$dropdown-border-radius: 0;
$dropdown-link-color: $primary;
$dropdown-link-hover-color: #FFF;
$dropdown-link-hover-bg:$primary;
$dropdown-link-active-color:       #FFF;
$dropdown-link-active-bg:$primary;
$dropdown-item-padding-y:8px;
$btn-border-radius: 0;
$font-weight-base: 300;

$h1-font-size:      1.7rem;
$h2-font-size:      1.5rem;
$h3-font-size:      1.3rem;
$h4-font-size:      1.1rem;
$blockquote-font-size: 1.3rem;
:root{
	--font-M: 'Montserrat Alternates', sans-serif;
	--primary : #E71F73;
	--secondary :#1D71B8;
	--primary-hover: #eb4188;
	--secondary-hover: #eb4188;
	--yellow: #FFD91A;
}

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";




/** useful **/
.ulli-reset{
	list-style-type: none;
	padding: 0;
}

.link-reset{
	color: inherit;
	text-decoration: none;
	&:hover{
		color: inherit;
		text-decoration: underline;
	}
}

.border-blue
{
	border-bottom: 1px solid #1d71b82b;
}


/* menu */

.nav-link{
	border-bottom: 2px solid transparent;
}

.dropdown-menu{
	border: none;
	border-top: 2px solid darken($primary, 10%);
	
}
.dropdown-item{
	font-weight: 300;
}
@media (max-width:991px){
	
	.navbar-toggler{
		color: $primary;

		&:focus{
			box-shadow: 0 0 0 0.1rem;
		}
		span{
			font-size: 2rem;
		}
	}

}
@media (min-width:992px){

.nav-link:not(.dropdown-toggle):hover, 
.nav-link:not(.dropdown-toggle):focus{
	border-color: darken($primary, 10%);
}

.dropdown-menu{
	top: calc(100% - 2px);
	box-shadow: 0 5px 10px 0 #e71f7326;
	
}
}


//buttons
.btn{
	box-shadow:  0px 2px 5px 0px #18181840;
}
.btn-primary{
	
	color: #FFF;

	&:hover{
		border-color: var(--primary-hover);
		color: #FFF;
	}

	
}

.btn.bottom-btn{
		bottom: 5%;
}

.btn.center-btn{
		left: 50%;
		transform: translateX(-50%);
	
}

h1{
	font-family: var(--font-L);
	text-transform: uppercase;
	display: block;
	//font-size: 1.7rem;
	font-weight: bold;
	width: auto;
	text-align: left;
	margin: 3rem auto 3rem;
	padding-bottom: 0.4rem;
	border-bottom: 2px solid $primary;
	color: $primary;

}

h2{
	text-transform: uppercase;
}

.bordered-bottom{
	&:after{
		content:'';
		display: block;
		width: 80px;
		height: 3px;
		background: #000;
		margin-top: 0.5rem ;
	}
}


blockquote{
	font-style: italic;
	margin-left: 1rem;
	width: calc(100% - 1rem);
	background: $light;
	font-size: $blockquote-font-size;    
	border-left: 5px solid $primary;   
	padding: 1rem 0 1rem 1rem; 
	p:last-of-type{
		margin-bottom: 0;
	}
}

a{
	font-weight: 400;
}


/*************** HTML CONTENT *************/
.html-content{
	h2{
		&:after{
			content:'';
			display: block;
			width: 80px;
			height: 3px;
			background: #000;
			margin-top: 0.5rem ;
		}
	}
}